import React, { useState, useEffect } from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { css } from "@emotion/react";
import { Icon } from "@ryerson/frontend.assets";
import styled from "@emotion/styled";
import { Typography } from "@ryerson/frontend.typography";
import { Input, EmailInput, Dropdown, PhoneInput } from "@ryerson/frontend.form-controls";
import { Button } from "@ryerson/frontend.button";
import { Media } from "@ryerson/frontend.layout";
import { ContactUsContent, TopicOptions } from "./FormContent";
import { useApplication } from "@ryerson/frontend.application";
import { Option } from "@ryerson/frontend.form-controls";
import { validateEmail } from "@ryerson/frontend.common";
import { Link } from "@ryerson/frontend.navigation";

import {
	sendGAEvent,
	GoogleAnalyticsEventName,
	GoogleAnalyticsEventActionNames,
} from "@ryerson/frontend.common";

export type FormContent = {
	requiredDirections: string;
	firstNameLabel: string;
	lastNameLabel: string;
	phoneNumberLabel: string;
	emailLabel: string;
	companyLabel: string;
	zipLabel: string;
	topicLabel: string;
	messageLabel: string;
	uploadFileLabel: string;
	fieldErrorMessage: string;
	blankTopicVerbiage: string;
	careersTopicVerbiage: string;
	onlineStoreTopicVerbiage: string;
	productQuestionTopicVerbiage: string;
	quoteRequestTopicVerbiage: string;
	requestSalesContactTopicVerbiage: string;
	mediaContactTopicVerbiage: string;
	otherTopicVerbiage: string;
	notValidZip: string;
	buttonLabel: string;
	modalMessageSuccess: string;
	modalMessageError: string;
	showLink?: boolean;
};

export type ContactUsDropdown = {
	options: Array<Option>;
};

export const Messager = styled.div`
	display: block;
	width: 100%;
	height: auto;
	margin-top: 20px;
`;

export const MessagerIcon = styled.div`
	display: inline-block;
	width: 30px;
	height: 30px;
	margin-right: 10px;
	vertical-align: middle;
	box-sizing: border-box;
	border-radius: 30px;
	text-align: center;
	line-height: 38px;
`;

export const MessagerText = styled.div`
	display: inline-block;
	vertical-align: middle;
	width: calc(100% - 40px);
	box-sizing: border-box;
`;

const ContactForm: React.FC = () => {
	const {
		localization: { language },
		axiosInstance,
	} = useApplication();

	const { theme } = useTheme();

	const inputStyling = css`
		box-sizing: border-box;
		margin-bottom: 20px;
		@media only screen and (max-width: ${theme.breakpoints.lg}) {
			margin-bottom: 15px;
		}
	`;

	const mainContainer = css`
		max-width: 70%;
		@media only screen and (max-width: ${theme.breakpoints.lg}) {
			max-width: 100%;
			margin-top: 30px;
		}
	`;

	const companyContainer = css`
		display: inline-block;
		box-sizing: border-box;
		width: 73%;
		margin-right: 2%;
		vertical-align: top;
		@media only screen and (max-width: ${theme.breakpoints.lg}) {
			width: 100%;
			margin-right: 0px;
		}
	`;

	const zipContainer = css`
		display: inline-block;
		box-sizing: border-box;
		width: 25%;
		vertical-align: top;
		@media only screen and (max-width: ${theme.breakpoints.lg}) {
			width: 100%;
		}
	`;

	const [topicVerbiage, setTopicVerbiage] = useState<string>(
		ContactUsContent[language].blankTopicVerbiage
	);

	const [submitted, setSubmitted] = useState<boolean>(false);
	const [firstName, setFirstName] = React.useState<string>("");
	const [lastName, setLastName] = React.useState<string>("");
	const [email, setEmail] = React.useState<string>("");
	const [phoneNumber, setPhoneNumber] = React.useState<string>("");
	const [zip, setZip] = React.useState<string>("");
	const [company, setCompany] = React.useState<string>("");
	const [topic, setTopic] = React.useState<string>("");
	const [message, setMessage] = React.useState<string>("");

	const [showAPIMessage, setShowAPIMessage] = React.useState<boolean>(false);
	const [apiMessage, setAPIMessage] = React.useState<string>("");
	const [apiStatus, setAPIStatus] = React.useState<string>("");

	React.useEffect(() => {
		if (window) {
			let hash = window.location.hash.replace("#", "");
			if (hash.length > 0) {
				handleTopicChange(hash);
				// window.location.hash = "";
			}
		}
	}, []);

	const handleTopicChange = (value: string) => {
		setTopic(value);
	};

	const displayAddlTopicVerbiage = (value: string) => {
		if (value === "") {
			setTopicVerbiage(ContactUsContent[language].blankTopicVerbiage);
		} else if (value === "careers") {
			setTopicVerbiage(ContactUsContent[language].careersTopicVerbiage);
		} else if (value === "onlineStore") {
			setTopicVerbiage(ContactUsContent[language].onlineStoreTopicVerbiage);
		} else if (value === "productQuestion") {
			setTopicVerbiage(ContactUsContent[language].productQuestionTopicVerbiage);
		} else if (value === "quoteRequest") {
			setTopicVerbiage(ContactUsContent[language].quoteRequestTopicVerbiage);
		} else if (value === "requestSalesContact") {
			setTopicVerbiage(ContactUsContent[language].requestSalesContactTopicVerbiage);
		} else if (value === "mediaContact") {
			setTopicVerbiage(ContactUsContent[language].mediaContactTopicVerbiage);
		} else {
			setTopicVerbiage(ContactUsContent[language].otherTopicVerbiage);
		}
	};

	const emailValidation = () => {
		return validateEmail(email, true) === "";
	};

	useEffect(() => {
		displayAddlTopicVerbiage(topic);
	}, [language, topic]);

	const onFormSubmit = () => {
		setShowAPIMessage(false);
		setSubmitted(true);
		if (
			firstName.length > 0 &&
			lastName.length > 0 &&
			email.length > 0 &&
			emailValidation() &&
			zip.length > 0 &&
			topic.length > 0 &&
			message.length > 0
		) {
			sendGAEvent(GoogleAnalyticsEventName.CONTACT_US, {
				action: GoogleAnalyticsEventActionNames.SUBMIT,
				value: topic,
			});
			let saveObject: object = {
				firstName: firstName,
				lastName: lastName,
				email: email,
				phoneNumber: String(phoneNumber),
				company: company,
				zip: String(zip),
				topic: topic,
				message: message,
			};
			axiosInstance
				.post("/api/common/v1/public/contact-us", saveObject)
				.then((response) => {
					if (response.status === 200) {
						setAPIMessage(
							"Thank you, your request has been received! One of our metal professionals will be following up with you shortly to assist."
						);
						setAPIStatus("success");
						setFirstName("");
						setLastName("");
						setEmail("");
						setPhoneNumber("");
						setCompany("");
						setZip("");
						setTopic("");
						setMessage("");
						setSubmitted(false);
						displayAddlTopicVerbiage("");
						setShowAPIMessage(true);
						setTimeout(() => {
							setShowAPIMessage(false);
						}, 8000);
					} else {
						setAPIMessage(ContactUsContent[language].modalMessageError);
						setSubmitted(false);
						setShowAPIMessage(true);
						setAPIStatus("error");
						setTimeout(() => {
							setShowAPIMessage(false);
						}, 8000);
					}
				})
				.catch(() => {});
		} else {
			setAPIStatus("error");
			setAPIMessage("Please enter all required fields.");
			setShowAPIMessage(true);
			setTimeout(() => {
				setShowAPIMessage(false);
			}, 8000);
		}
	};

	return (
		<div css={mainContainer}>
			<Media greaterThanOrEqual="lg">
				<Typography variant="p" size="xs" color={theme.colors.primary.gray} italic={true}>
					{ContactUsContent[language].requiredDirections}
				</Typography>
				<Input
					label={ContactUsContent[language].firstNameLabel}
					value={firstName}
					onChange={(e) => {
						setFirstName(e.target.value);
					}}
					required={true}
					submitted={submitted}
					css={inputStyling}
					language={language}
				/>
				<Input
					label={ContactUsContent[language].lastNameLabel}
					value={lastName}
					onChange={(e) => {
						setLastName(e.target.value);
					}}
					required={true}
					submitted={submitted}
					css={inputStyling}
					language={language}
				/>
				<EmailInput
					label={ContactUsContent[language].emailLabel}
					value={email}
					onChange={(e) => {
						setEmail(e.target.value);
					}}
					required={true}
					submitted={submitted}
					placeholder={""}
					css={inputStyling}
					isValid={submitted ? emailValidation() : true}
					language={language}
				/>
				<PhoneInput
					label={ContactUsContent[language].phoneNumberLabel}
					value={phoneNumber}
					onChange={(e) => {
						setPhoneNumber(e.target.value);
					}}
					required={false}
					submitted={submitted}
					css={inputStyling}
					language={language}
				/>
				<div css={companyContainer}>
					<Input
						label={ContactUsContent[language].companyLabel}
						value={company}
						onChange={(e) => {
							setCompany(e.target.value);
						}}
						required={false}
						submitted={submitted}
						css={inputStyling}
						language={language}
					/>
				</div>
				<div css={zipContainer}>
					<Input
						label={ContactUsContent[language].zipLabel}
						value={zip}
						onChange={(e) => {
							setZip(e.target.value);
						}}
						required={true}
						submitted={submitted}
						css={inputStyling}
						language={language}
					/>
				</div>
				<Dropdown
					label={ContactUsContent[language].topicLabel}
					size={"md"}
					value={topic}
					onChange={handleTopicChange}
					options={TopicOptions[language].options}
					required={true}
					css={inputStyling}
					language={language}
				/>

				<Typography variant="p">
					{topicVerbiage}
					{topic === "careers" && (
						<Link to="/about-us/careers/open-positions/">Click here</Link>
					)}
					.
				</Typography>
				<Input
					label={ContactUsContent[language].messageLabel}
					value={message}
					onChange={(e) => {
						setMessage(e.target.value);
					}}
					inputType="textarea"
					required={true}
					css={inputStyling}
					addStyle={{ height: "138px" }}
					language={language}
				/>
				<Button
					label={ContactUsContent[language].buttonLabel}
					onClick={onFormSubmit}
					paddingType="relaxed"
					size="lg"
				/>
			</Media>
			<Media lessThan="lg">
				<Typography
					variant="p"
					size="xs"
					color={theme.colors.primary.gray}
					italic={true}
					css={{ marginBottom: "20px" }}
				>
					{ContactUsContent[language].requiredDirections}
				</Typography>
				<Input
					label={ContactUsContent[language].firstNameLabel}
					value={firstName}
					onChange={(e) => {
						setFirstName(e.target.value);
					}}
					required={true}
					submitted={submitted}
					css={inputStyling}
					language={language}
				/>
				<Input
					label={ContactUsContent[language].lastNameLabel}
					value={lastName}
					onChange={(e) => {
						setLastName(e.target.value);
					}}
					required={true}
					submitted={submitted}
					css={inputStyling}
					language={language}
				/>
				<EmailInput
					label={ContactUsContent[language].emailLabel}
					value={email}
					onChange={(e) => {
						setEmail(e.target.value);
					}}
					required={true}
					submitted={submitted}
					placeholder={""}
					css={inputStyling}
					language={language}
				/>
				<PhoneInput
					label={ContactUsContent[language].phoneNumberLabel}
					value={phoneNumber}
					onChange={(e) => {
						setPhoneNumber(e.target.value);
					}}
					required={false}
					submitted={submitted}
					css={inputStyling}
					language={language}
				/>
				<div css={companyContainer}>
					<Input
						label={ContactUsContent[language].companyLabel}
						value={company}
						onChange={(e) => {
							setCompany(e.target.value);
						}}
						required={true}
						submitted={submitted}
						css={inputStyling}
						language={language}
					/>
				</div>
				<div css={zipContainer}>
					<Input
						label={ContactUsContent[language].zipLabel}
						value={zip}
						onChange={(e) => {
							setZip(e.target.value);
						}}
						required={true}
						submitted={submitted}
						css={inputStyling}
						language={language}
					/>
				</div>
				<Dropdown
					label={ContactUsContent[language].topicLabel}
					size={"md"}
					value={topic}
					onChange={handleTopicChange}
					options={TopicOptions[language].options}
					required={true}
					css={inputStyling}
					language={language}
				/>
				<Typography variant="p" size="md">
					{topicVerbiage}
					{topic === "careers" && (
						<Link to="/about-us/careers/open-positions/">Click here</Link>
					)}
					.
				</Typography>
				<Input
					label={ContactUsContent[language].messageLabel}
					value={message}
					onChange={(e) => {
						setMessage(e.target.value);
					}}
					inputType="textarea"
					required={true}
					css={inputStyling}
					addStyle={{ height: "138px" }}
					language={language}
				/>
				<Button
					label={ContactUsContent[language].buttonLabel}
					onClick={onFormSubmit}
					paddingType="relaxed"
					fullwidth={true}
					size="lg"
				/>
			</Media>
			{showAPIMessage && (
				<Messager>
					<MessagerIcon
						css={css`
							background-color: ${apiStatus === "success"
								? theme.colors.secondary.green
								: theme.colors.secondary.red};
						`}
					>
						<Icon
							size="sm"
							color={theme.colors.primary.white}
							icon={apiStatus === "success" ? "check" : "minus"}
						/>
					</MessagerIcon>
					<MessagerText>
						<Typography variant="div" size="md" type="primary">
							{apiMessage}
						</Typography>
					</MessagerText>
				</Messager>
			)}
		</div>
	);
};

export default ContactForm;
